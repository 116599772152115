var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.customers,"loading":_vm.loadingCustomers,"search-input":_vm.searchCustomer,"placeholder":_vm.$t('customer name or number'),"item-text":"text","item-value":"value","hide-no-data":"","hide-selected":"","no-filter":"","clearable":"","single-line":""},on:{"update:searchInput":function($event){_vm.searchCustomer=$event},"update:search-input":function($event){_vm.searchCustomer=$event},"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.customerNumber),callback:function ($$v) {_vm.customerNumber=$$v},expression:"customerNumber"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.staffUsernames,"loading":_vm.loadingStaffUsernames,"placeholder":_vm.$t('staff username'),"item-text":"value","item-value":"value","hide-selected":"","hide-no-data":"","clearable":"","single-line":""},on:{"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.staffUsername),callback:function ($$v) {_vm.staffUsername=$$v},expression:"staffUsername"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.views,"item-text":"value","item-value":"value","search-input":_vm.searchView,"loading":_vm.loadingViews,"placeholder":_vm.$t('backend view'),"hide-selected":"","hide-no-data":"","clearable":"","single-line":""},on:{"update:searchInput":function($event){_vm.searchView=$event},"update:search-input":function($event){_vm.searchView=$event},"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.httpMethodes,"placeholder":_vm.$t('HTTP method'),"item-text":"text","item-value":"value","hide-selected":"","clearable":"","single-line":"","return-object":false},on:{"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.httpMethod),callback:function ($$v) {_vm.httpMethod=$$v},expression:"httpMethod"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-combobox',{attrs:{"items":_vm.httpStatuses,"placeholder":_vm.$t('HTTP status'),"item-text":"text","item-value":"value","hide-selected":"","clearable":"","single-line":"","return-object":false},on:{"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.httpStatus),callback:function ($$v) {_vm.httpStatus=$$v},expression:"httpStatus"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"click:append-outer":function($event){_vm.options.page = 1;
            _vm.getLogs();}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('IP address'),"single-line":"","hide-details":""},on:{"click:append-outer":function($event){_vm.options.page = 1;
            _vm.getLogs();}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.ipAddr),callback:function ($$v) {_vm.ipAddr=$$v},expression:"ipAddr"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":_vm.$t('start date'),"hide-icon":"","clearable":"","no-form":""},on:{"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.dateAfter),callback:function ($$v) {_vm.dateAfter=$$v},expression:"dateAfter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":_vm.$t('end date'),"hide-icon":"","clearable":"","no-form":""},on:{"input":function($event){_vm.options.page = 1;
            _vm.getLogs();}},model:{value:(_vm.dateBefore),callback:function ($$v) {_vm.dateBefore=$$v},expression:"dateBefore"}})],1)],1)],1),_c('v-card-text',[_c('data-table-extended',{attrs:{"headers":_vm.headers,"items":_vm.logs,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"disable-sort":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"created","sort-desc":"","hide-default-footer":_vm.itemsTotal <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"show-expand":"","single-expand":"","show-menu":"","local-storage-name":"accessLogList","open-expand-by-dbl-click-row":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function({ item }){return [_c('div',{staticClass:"monospace text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.created))+" ")])]}},{key:"item.elapsed_time",fn:function({ item }){return [_c('div',{staticClass:"monospace text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDuration(item.elapsed_time, true))+" ")])]}},{key:"item.secure",fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.secure),callback:function ($$v) {_vm.$set(item, "secure", $$v)},expression:"item.secure"}})]}},{key:"item.staff",fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.staff),callback:function ($$v) {_vm.$set(item, "staff", $$v)},expression:"item.staff"}})]}},{key:"item.url",fn:function({ item }){return [_c('span',{staticClass:"d-inline-block text-truncate",style:('max-width:' + _vm.urlSize + 'px;')},[_vm._v(" "+_vm._s(item.url)+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('access-log-details',{staticClass:"my-3",attrs:{"id":item.id,"base-url":_vm.getBaseUrl()}})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }